
import {httpPostRequest} from "./util/misc";
import firebase from 'firebase';

let auth        = firebase.auth();

class EdutechCloudServer {

    constructor(){
        // ====== IMPORTANT NEEDS THE SLASH AT THE END OF THIS URL ============ //
        //this.serverRootDomain = "http://localhost:8383/";
        this.serverRootDomain = "https://edtch.pw/";  //FOR DEPLOY --

        //fail-safe that in live environment localhost servers will never be used..
        if ((window.location.href).includes('edutechs.app')){
            this.serverRootDomain = "https://edtch.pw/";
        }
        //console.log('EdutechCloudServer Location has been configured to be: ', this.serverRootDomain);
    } 

    getServerAddress(){
        let x = this.serverRootDomain.substring(0, this.serverRootDomain.length - 1);
        return x;
    }


    //e.g EdutechCloudServer.call('api-sessions/push-notification', {'foo':'bar'}, (response)=>{});
    call(url, inputJSON, callback){

        //this will call a function in our edutechCloudServer
        let fullAPI_URL = this.serverRootDomain + url

        //console.log('Calling EDUTECH CLOUD SERVER REST API URL ENDPOINT: ', fullAPI_URL);

        if (!inputJSON){
            inputJSON = {};
        }

        //first fetch session firebase Id TOKEN from auth
        auth.currentUser.getIdToken(true).then(function(idToken) {

            //now set the idToken
            inputJSON['idToken'] = idToken;

            //console.log('HTTP POST REQUEST MADE');
            httpPostRequest(
                fullAPI_URL,
                inputJSON,
                `Edutech Cloud Server Call Error when calling - ${url}`,
                (response) => {
                    //console.log('HTTP REQUEST RESPONSE CAME');
                    callback(response);
                }
            );

        });
    }

    getRequest(url, callback) {
        const fullAPI_URL = this.serverRootDomain + url;

        //console.log('Calling EDUTECH CLOUD SERVER REST API GET URL ENDPOINT: ', fullAPI_URL);

        auth.currentUser.getIdToken(true).then((idToken) => {
            // Passing idToken as a query string, so that we can check the auth status from server side
            let url = new URL(fullAPI_URL);
            url.search = new URLSearchParams({
                idToken: idToken
            })

            fetch(url)
                .then(data => data.json())
                .then(res => callback(res))
                .catch(e => callback(e));
        });
    }

    postRequest(url, data={}, callback) {
        const fullAPI_URL = this.serverRootDomain + url;

        console.log('Calling EDUTECH CLOUD SERVER REST API POST URL ENDPOINT: ', fullAPI_URL);

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }

        fetch(fullAPI_URL, options)
            .then(data => data.json())
            .then(res => callback(res))
            .catch(e => callback(e));
    }

    isWorkingProperlyAndIsUp(callback){

        const fullAPI_URL   = this.serverRootDomain + 'api-sessions/test';
        let data            = {};

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }

        fetch(fullAPI_URL, options)
            .then(data => data.json())
            .then(res => callback(res))
            .catch(e => callback(e));


    }
}

export default new EdutechCloudServer()





//cd to EdutechServer2.0 and then nohup node server.js https ownIP 443 &
//pgrep -a node
//kill PID
//tail -f nohup.out
import firebase from "firebase";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyC1WuRzzWGnT_6gkEdj2SMjnq8xN-D3yaY",
    authDomain: "reachsms-a3f1a.firebaseapp.com",
    databaseURL: "https://reachsms-a3f1a-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "reachsms-a3f1a",
    storageBucket: "reachsms-a3f1a.appspot.com",
    messagingSenderId: "945178422018",
    appId: "1:945178422018:web:e4417957c85060a110301b",
    measurementId: "G-6WZ57K4H2G"
};

firebase.initializeApp(config);

const auth                          = firebase.auth();
const database                      = firebase.database();


export {
  database,
  auth
};


import firebase from 'firebase';
import store from "./store";

import {  set } from "lodash-es";
import { memorySizeOf, toDataURL, resizeImage, encodeString, arrayHasUndefined, does_object_have_child_keyName } from "./util/misc";



let database    = firebase.database();
let auth        = firebase.auth();


class Database {
    /**
     * Initializes the local store with data and time from firebase server. Returns
     * a Promise that resolves to the store state
     * @returns {Promise<Object>}
     */
    async initialRead(uid, isFirst, dispatch, updateInLocalDatabase) {

        let uidUSED = uid;
    
        database.ref(`USERS/${uidUSED}`).on("value", snapshot=>{

            let userData = snapshot.val();

            if (!snapshot.exists()){
                console.log('User Data for this UID was undefined!');
                auth.signOut();
                return {};
            } 

            if (isFirst === true){
                isFirst = false;
            }

            dispatch(updateInLocalDatabase({objToAdd: userData}));

        });

    }


    async downloadDatabase_SetupStore_redirectDashboard(uid, dispatch, updateInLocalDatabase){

        console.log('SETUP downloadDatabase_SetupStore_redirectDashboard.. ', uid);

        //check to first see if there is an existing account under this UID
        await this.initialRead(uid);

        if (store.getState()['PublicInfo'] === undefined){
            auth.signOut();
            return false;   
        }
    
        //need to check here to see if this uid is student or teacher

    
    }
    
    
    async createNewStudentDatabase({uid, email, phone, name, type}){

        if (!email) email = 'unset@gmail.com';
        if (!phone) phone = 'Unset';

        let ref = database.ref('USERS/' + uid);
        let data = {
            UID: uid,
            PublicInfo: {
                UID: uid,
                UserName: encodeString(name),
                UserEmail: encodeString(email),
                UserProffession: 'Student',
                UserInstitution: 'Unset',
                UserPhone: encodeString(phone),
                avatarURL: 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png',
            }
        }

        let p1 = ref.update(data);
        let p2 = database.ref(`email-uid/`).update({
            [encodeString(email)]:  uid
        })

        return Promise.all([p1, p2]);
    }

    async createNewTeacherDatabase({uid, email, phone, name, type, referralID, isSignUpZoom}){

        if (!email) email = 'unset@gmail.com';
        if (!phone) phone = 'Unset';
        
        console.log('Creating New Teacher Database with VAL: ', {uid, email, name, phone});

        let ref = database.ref('USERS/' + uid);

        let timeStamp = new Date().getTime();

        let data = {
            UID: uid,
            PublicInfo: {
                UID             : uid,
                UserName        : encodeString(name),
                UserEmail       : encodeString(email),
                UserProffession : "Teacher",
                TeacherType     : "Institution",
                OnlinePayment   : "Off",
                UserInstitution : 'unset',
                UserPhone       : encodeString(phone),
                UserLocation    : 'unset',
                avatarURL       : "https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png",
                Date            : timeStamp,
            }
        };

        if (referralID){
            data['ReferredBy'] = {'UID':referralID};
            //need to update the referred database that he has successfully refrred an account
            database.ref(`USERS/${referralID}/PublicInfo/Referrals/${uid}`).update({
                referralID  : uid,
                timeStamp   : (new Date()).getTime()
            })
        }


        let p1 = ref.update(data);
        let p2 = database.ref(`email-uid/`).update({
            [encodeString(email)]:  uid
        });

        let p3
        let p4
        let p5
        let p6

        if(isSignUpZoom){
            p3 = database.ref(`USERS/${uid}`).update({ReactAppMode : 'SMS_LMS_GATEWAY'})
            p4 = database.ref(`USERS/${uid}/PublicInfo/DO-NOT-SHOW-NEWS-KEYS`).update({'1662748674': '1662748674'})

            let data = {
                activated       :   (new Date()).getTime(),
                price           :   '0',
                by              :   uid,
                route           :   'zoom-meeting',
                key             :   'ZoomAppIntegration'
            }

           p5 = database.ref(`USERS/${uid}/InstalledTools`).update({
                'ZoomAppIntegration' :data
            })

            p6 = database.ref(`USERS/${uid}/pinnedTools/ZoomAppIntegration`).update({
                'enabled' : true,
                'route'   :'zoom-meeting',
                'title'   : 'Zoom Integration',
                'sideBarIcon' : 'https://f002.backblazeb2.com/file/Edutech-Assets/React-App-AddOns-Icons/icons8-zoom-400.png'
            })
           
        }



        return Promise.all([p1, p2, p3, p4, p5, p6]);
    }

    async createNewBusinessDatabase({uid, email, phone, name, referralID}){

        if (!email) email = 'unset@gmail.com';
        if (!phone) phone = 'Unset';
        
        console.log('Creating New Business Database with VAL: ', {uid, email, name, phone});

        let ref = database.ref('USERS/' + uid);

        let timeStamp = new Date().getTime();

        let data = {
            UID: uid,
            PublicInfo: {
                UID             : uid,
                UserName        : encodeString(name),
                UserEmail       : encodeString(email),
                UserProffession : "Business",
                OnlinePayment   : "Off",
                UserInstitution : 'unset',
                UserPhone       : encodeString(phone),
                UserLocation    : 'unset',
                avatarURL       : "https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png",
                Date            : timeStamp,
            }
        };

        if (referralID){
            data['ReferredBy'] = {'UID':referralID};
            //need to update the referred database that he has successfully refrred an account
            database.ref(`USERS/${referralID}/PublicInfo/Referrals/${uid}`).update({
                referralID  : uid,
                timeStamp   : (new Date()).getTime()
            })
        }


        let p1 = ref.update(data);

        return Promise.all([p1]);
    }

    async createNewParentDatabase({uid, email, phone, name, type}){

        if (!email) email = 'unset@gmail.com';
        if (!phone) phone = 'Unset';

        let ref = database.ref('USERS/' + uid);
        let data = {
            UID: uid,
            PublicInfo: {
                UID: uid,
                UserName: encodeString(name),
                UserEmail: encodeString(email),
                UserProffession: 'Parent',
                UserInstitution: 'Unset',
                UserPhone: encodeString(phone),
                avatarURL: 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png',
            }
        }

        let p1 = ref.update(data);
        let p2 = database.ref(`email-uid/`).update({
            [encodeString(email)]:  uid
        });

        Promise.all([p1, p2]);
    }


    async createNewAdminDatabase({uid, email, name, phone, type}){

        console.log('Creating New ADMIN Database with VAL: ', {uid, email, name, phone});


        if (!email) email = 'unset@gmail.com';
        if (!phone) phone = 'Unset';

        let ref = database.ref('USERS/' + uid);
        
        let data = {
            UID: uid,
            PublicInfo: {
                UID: uid,
                UserName: encodeString(name),
                UserEmail: encodeString(email),
                UserProffession: 'Admin',
                UserInstitution: 'Unset',
                UserPhone: encodeString(phone),
                avatarURL: 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png',
            }
        }

        let p1 = ref.update(data);
        let p2 = database.ref(`email-uid/`).update({
            [encodeString(email)]:  uid
        });

        return Promise.all([p1, p2]);
    }

    async removeOneStudentFromBatch({studentUID, batch}){
        return Promise.all([database.ref(`USERS/${studentUID}/AcceptedClasses/${batch}`).remove(), database.ref(`USERS/${studentUID}/PendingClasses/${batch}`).remove()])
    }

    async removeModeratorFromBatch({moderatorUID, batch}){
        return Promise.all([database.ref(`USERS/${moderatorUID}/AcceptedBatches/${batch}`).remove(), database.ref(`USERS/${moderatorUID}/PendingBatches/${batch}`).remove()])
    }

    getState() {
        return store.getState();
    }


    /**
     *
     * @param {Array<String>} pathArray
     */
    getNestedData(pathArray) {

        return store.getNestedData(pathArray);
    }

    /**
     *
     * @param {Array<String>} pathArray
     */
    getRelativeNestedData(pathArray){
        let output = store.getNestedData(pathArray);

        //check to see if output is a relative address
        if (typeof (output) === 'string') {

            if (output.startsWith("{{") && output.endsWith("}}")) {

                //strip first two and last two characters
                let newPath = output.substr(2);
                let _newPath = newPath.substring(0, newPath.length - 2);
                //console.log('Cloud Fetching from address ', _newPath);
                let promise = database.ref(_newPath).once('value');

                return promise
            }
            else {
                console.error("Relative Path syntax error. Does not start and end with double flower brackets.");
                return Promise.reject(new Error("Relative Database Fetch failed"));
            }
        }
        else{
            console.error("Non string relative Path");
            return Promise.reject(new Error("Relative Database Fetch failed"));
        }
    }



    /**
     * Update the 'path' in the database with 'data'
     * @param {Array<String>} pathArray
     * @param {Object} dataObj
     * @returns {Promise<>} - Returns a promise that fulfills to undefined
     */
    update(pathArray, dataObj) {

        if (!(pathArray instanceof Array)) {
            console.error("Non array passed as path to db.update()");
            return Promise.reject(new Error("Database update failed"));
        }

        if (typeof dataObj != 'object') {
            console.error(`An 'object' must be passed to database.update() as data. Got a '${typeof dataObj}' instead.`);
            return Promise.reject(new Error("Database update failed"));
        }

        if (arrayHasUndefined(pathArray)){
            //console.log('Passed in Array: ', pathArray);
            console.error(`Passed in array has undefined value`);
            return Promise.reject(new Error("Database update failed"));
        }


        for (let p of pathArray){
            if (p === undefined) {
                console.log(p);
                console.error("Path Array contains undefined element");
                return Promise.reject(new Error("Could not add to database"));
            }
            else if (p === '' && p!== 0){
                console.log(p);
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
            else if (p === ""){
                console.log(p);
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
        }

        let ownUID = Database.prototype.getNestedData(['UID']);
        let dbpath = ["USERS", ownUID, ...pathArray];
        let address = dbpath.join("/");

        let updatePromise = database.ref(address).update(dataObj);

        let existingData = store.getNestedData(pathArray);
        store.update(pathArray, { ...existingData, ...dataObj });

        return updatePromise
            .catch(err => {
                //revert store updates
                store.update(pathArray, existingData);
            });
    }



    /**
     * Delete the data in the database location defined by 'path'
     * @param {Array<String>} pathArray
     * @returns {Promise<>}
     */
    remove(pathArray) {
        if (!(pathArray instanceof Array)) {
            console.error("Non array passed as path to db.remove()");
            return Promise.reject(new Error("Could not remove from database"));
        }

        if (arrayHasUndefined(pathArray)){
            console.error(`Passed in array has undefined value`);
            return Promise.reject(new Error("Database update failed"));
        }

        for (let p of pathArray){
            if (p === undefined) {
                console.error("Path Array contains undefined element");
                return Promise.reject(new Error("Could not add to database"));
            }
            else if (p === ''){
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
            else if (p === ""){
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
        }

        let ownUID = Database.prototype.getNestedData(['UID']);
        let dbpath = ["USERS", ownUID, ...pathArray];
        let address = dbpath.join("/");

        let removePromise = database.ref(address).remove();

        return removePromise
            .then(() => {
                store.remove(pathArray);
            })
            .catch(err => {
            });
    }



    /**
     * Push 'data' to the database address specified by 'pathArray'
     * @param {Array<String>} pathArray
     * @param {Object} data
     * @returns {Promise<>} - Returns a promise that fulfills to undefined
     */
    push(pathArray, data) {
        if (!(pathArray instanceof Array)) {
            console.error("Non array passed as path to db.push()");
            return Promise.reject(new Error("Could not push to database"));
        }

        if (arrayHasUndefined(pathArray)){
            console.error(`Passed in array has undefined value`);
            return Promise.reject(new Error("Database update failed"));
        }

        for (let p of pathArray){
            if (p === undefined) {
                console.error("Path Array contains undefined element");
                return Promise.reject(new Error("Could not add to database"));
            }
            else if (p === ''){
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
            else if (p === ""){
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
        }

        let ownUID = Database.prototype.getNestedData(['UID']);

        let dbpath = ["USERS", ownUID, ...pathArray];
        let address = dbpath.join("/");

        let pushPromise = database.ref(address).push(data);

        store.update([...pathArray, pushPromise.key], data);
        //window.sessionStorage.setItem('Store', JSON.stringify(store.getState()));

        let storePushPromise = pushPromise
            .catch(err => {
                store.remove([...pathArray, pushPromise.key]);
            });

        storePushPromise.key = pushPromise.key;
        return storePushPromise;
    }


    /**
     * @param {Array<String>} pathArray 
     * @param {String} oldKey 
     * @param {String} newKey 
     */
    changeKey(pathArray, oldKey, newKey) {
        if (!(pathArray instanceof Array)) {
            console.error("Non array passed as path to db.changeKey()");
            return Promise.reject(new Error("Database key update failed"));
        }

        if (arrayHasUndefined(pathArray)){
            console.error(`Passed in array has undefined value`);
            return Promise.reject(new Error("Database update failed"));
        }

        for (let p of pathArray){
            if (p === undefined) {
                console.error("Path Array contains undefined element");
                return Promise.reject(new Error("Could not add to database"));
            }
            else if (p === ''){
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
            else if (p === ""){
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
        }

        let data = store.getNestedData([...pathArray, oldKey]);

        if (!data) {
            console.error("Invalid path/key to db.changeKey()");
            return Promise.reject(new Error("Database key update failed"));
        }

        let ownUID = Database.prototype.getNestedData(['UID']);
        let dbpath = ["USERS", ownUID, ...pathArray];
        let address = dbpath.join("/");

        let dbRef = database.ref(address);

        return dbRef.child(newKey).update(data)
            .then(() => {
                store.update([...pathArray, newKey], data)
                //window.sessionStorage.setItem('Store', JSON.stringify(store.getState()));
                return dbRef.child(oldKey).remove();
            })
            .then(() => {
                store.remove([...pathArray, oldKey]);
                //window.sessionStorage.setItem('Store', JSON.stringify(store.getState()));
            })
            .catch(err => console.error(err));
    }

    get time() {
        return store.time;
    }

    // Read data from real time database and be able to work with the result in a callback
    cloudRead(pathArray, callback) {
        let promise = database.ref(pathArray.join('/')).once('value');
        promise.then(snapshot => {

            let resultData = snapshot.val();

            callback(resultData);
        })
    }

    //Write and Update data into cloud
    cloudWrite(pathArray, writeData) {

        if (!(pathArray instanceof Array)) {
            console.error("Non array passed as path to db.cloudWrite()");
            return Promise.reject(new Error("Could not write to database"));
        }

        for (let p of pathArray){
            if (p === undefined) {
                console.error("Path Array contains undefined element");
                return Promise.reject(new Error("Could not add to database"));
            }
            else if (p === ''){
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
            else if (p === ""){
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
        }

        let promise = database.ref(pathArray.join('/')).update(writeData);
        return promise;
    }

    //Write data in unique key by using push
    cloudPush(pathArray, writeData) {

        if (!(pathArray instanceof Array)) {
            console.error("Non array passed as path to db.cloudpush()");
            return Promise.reject(new Error("Could not push to database"));
        }

        for (let p of pathArray){
            if (p === undefined) {
                console.error("Path Array contains undefined element");
                return Promise.reject(new Error("Could not add to database"));
            }
            else if (p === ''){
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
            else if (p === ""){
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
        }

        let promise = database.ref(pathArray.join('/')).push(writeData);
        return promise;
    }

    //Delete data in cloud
    cloudDelete(pathArray) {
        if (!(pathArray instanceof Array)) {
            console.error("Non array passed as path to db.cloudDelete()");
            return Promise.reject(new Error("Could not delete from database"));
        }

        for (let p of pathArray){
            if (p === undefined) {
                console.error("Path Array contains undefined element");
                return Promise.reject(new Error("Could not remove from database"));
            }
            else if (p === ''){
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not remove from database"));
            }
            else if (p === ""){
                console.error("Path Array contains empty string");
                return Promise.reject(new Error("Could not add to database"));
            }
        }

        let promise = database.ref(pathArray.join('/')).remove();
        return promise; 
    }

    //this will set up a listening function where it will listen for any CHANGES in database and execute callback function when it happens
    //IMPORTANT - PATHARRAY NEEDS COMPLETE PATH e.g ['USERS', 'hutSIqLbIySZUWCu50FPzF2RaCv2','UserClass', 'A LEVEL', 'Chemistry', 'Testing']
    cloudListenForChildChange(pathArray, callback) {
        let path = pathArray.join('/');
        //console.log(`Listening for Existing Child Changes at: ${path}`);
        database.ref(path).on('child_changed', (snapshot) => {
            let parentPath = snapshot.getRef().path.pieces_;
            parentPath.shift();
            parentPath.shift();

            callback(snapshot);
            store.update(parentPath, snapshot.val());
            //window.sessionStorage.setItem('Store', JSON.stringify(store.getState()));
        });
    }

    //this will set up a listening function where it will listen for any ADDITIONS in database and execute callback function when it happens
    //IMPORTANT - PATHARRAY NEEDS COMPLETE PATH e.g ['USERS', 'hutSIqLbIySZUWCu50FPzF2RaCv2','UserClass', 'A LEVEL', 'Chemistry', 'Testing']
    cloudListenForChildAdded(pathArray, callback) {

        let path = pathArray.join('/') + '/';

        // console.log(`Listening for Child Added at: ${path}`);
        
        // TODO: limitToLast(1), first time it will listen only for last element. Then if one child added, it will listen 2 times
        //       , then if another added, it will listen 3 times and so on...
        //       , suppose you have 1k messages, without limitToLast(1), it will listen everytime (1k + number of element you added) 
        database.ref(path).on('child_added', function(snapshot) {
            //console.log('Child added was triggered!');
            //need to first compare and see if this already exists in local store
            //if it does then do not trigger this function
            let parentPath = snapshot.getRef().path.pieces_;
            
            parentPath.shift();
            parentPath.shift();

            let compareKey = snapshot.key;
            let snapshotVal = snapshot.val();
            let _snapshot = snapshot;
            parentPath.pop();

            //console.log('Parent Path ', parentPath);
            let jsonNodeToCompareIn = Database.prototype.getNestedData(parentPath);
            //console.log('jsonNodeToCompareIn ', jsonNodeToCompareIn);

            let booleanCompare = does_object_have_child_keyName(jsonNodeToCompareIn, compareKey);
            
            if (!booleanCompare) {    
                //console.log('New Data in Database. Trigerred Cloud Listen for child added');            
                //let existingData = store.getNestedData(parentPath);
                parentPath.push(compareKey);
                store.update(parentPath, snapshotVal);

                //console.log('--------store---------: ', store);

                //window.sessionStorage.setItem('Store', JSON.stringify(store.getState()));
                /*
                or, don't push comareKey to parentPath
                and, store.update(parentPath, { ...existingData, ...{[compareKey]: snapshotVal} });
                */
                callback(_snapshot);
            }
            
        });
    }

    //this will set up a listening function where it will listen for any REMOVALS in database and execute callback function when it happens
    //IMPORTANT - PATHARRAY NEEDS COMPLETE PATH e.g ['USERS', 'hutSIqLbIySZUWCu50FPzF2RaCv2','UserClass', 'A LEVEL', 'Chemistry', 'Testing']
    cloudListenForChildRemoved(pathArray, callback) {
        let path = pathArray.join('/') + '/';

        database.ref(path).on('child_removed', function(snapshot) {

            let parentPath = snapshot.getRef().path.pieces_;

            parentPath.shift();
            parentPath.shift();

            // first remove data from store
            // and then invoke callback function, so that store will be up-to-date
            store.remove(parentPath);
            callback(snapshot);
        });
    }

    /**
     * Convert an image to base64 and store in given path in the database
     * @param {Object} fileInputElem - the input file element in DOM that will bind to on change function
     * @param {Array<String>} pathArray
     * @param {Object} dataKey
     * @param {Integer} width
     * @param {Integer} height
     * @param {Function} callback  - has parameters new base 64 image url and the response promise after writing to database
     */
    storeImage(fileInputElem, width, height, pathArray, dataKey, callback){

        fileInputElem.change(function () {

            resizeImage(width, height, fileInputElem[0]).then(resizedImage => {

                //now convert the image to base64
                toDataURL(resizedImage.data, function (newImageURL) {

                    //now write this base64 image url into the database at specified address
                    let response = Database.prototype.update(pathArray, { [dataKey]: encodeString(newImageURL) });

                    callback(newImageURL, response);

                });
            })
            
        });

    }



    /**
     * Convert any file to base64 and store in given path in the database
     * By setting limitations on the input file type this can be used selectively for pdfs, word, text documents
     * @param {Object} Input - HTML Element 
     * @param {Array<String>} pathArray
     * @param {Object} dataKey
     * @param {Function} callback  - has parameters new base 64 file url and the response promise after writing to database
     */
    storeFile(fileInputElem, pathArray, dataKey, callback) {

        fileInputElem.change(function (event) {

            
            let files = event.target.files;
            let file = files[0];

            if (files && file) {
                let reader = new FileReader();

                reader.onload = function (readerEvt) {
                    let binaryString = readerEvt.target.result;
                    let b64 = (btoa(binaryString));

                    //now write this base64 image url into the database at specified address
                    let response = Database.update(pathArray, { [dataKey]: encodeString(b64) });

                    callback(b64, response);

                };

                reader.readAsBinaryString(file);
            }
            else {
                console.log('File Upload Failed');
            }

        });
        
    }


    checkCloudFunctionQuota(){
        //returns true if quota available and false if not available

        let totalAllowedQuota = parseInt(Database.getNestedData(['QuotaAllowance']));
        if (!totalAllowedQuota) {
            totalAllowedQuota = 0;

            Database.update([], {QuotaAllowance: 0});
        }


        let today = new Date(store.time);
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        let todayDate = dd + mm + yyyy;

        let usedUpQuota = Database.getNestedData(['QuotaUsageHistory', todayDate]);

        if (!usedUpQuota){

            Database.update(['QuotaUsageHistory'], {todayDate: 0});

            let currentUsedQuota = 0;

            if (currentUsedQuota<totalAllowedQuota){
                return true
            }
            else{
                return false
            }

        }

        else{

            let currentUsedQuota = parseInt(Database.getNestedData(['QuotaUsageHistory', todayDate]));

            if (currentUsedQuota<totalAllowedQuota){
                return true
            }
            else{
                return false
            }

        }

    }

    incrementCloudUsedQuota(){
        let today = new Date(store.time);
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        let todayDate = dd + mm + yyyy;

        let usedUpQuota = parseInt(Database.getNestedData(['QuotaUsageHistory', todayDate]));

        if (!usedUpQuota){
            usedUpQuota = 0;
        }

        let newUsedQuota = usedUpQuota + 1;

        Database.update(['QuotaUsageHistory'], {todayDate: newUsedQuota});
    }

    /**
     * @desc This will renumber a file layer in the Database and then fix children path references by recursion
     * @param {Array<String>} pathArray - path that needs to be renumbered
     * @param {String} subject - This needs to be called with the empty string value '' With subsequent recursion cycles this will be set to previous calculated path
     * @param {String} grade - This needs to be called with the empty string value '' With subsequent recursion cycles this will be set to previous calculated path
     * @param {function} callback - the callback function to execute after recursion end
     * @return null
    **/
    reNumberFileTreeLayer(pathArray, subject, grade,  recursiveDeleteBool, callback){

        console.log('FILE TREE LAYER NEEDS TO BE RE_NUMBERED WITH: ', pathArray);

        if (!(pathArray instanceof Array)) {
            console.error("Non array passed as path to db.update()");
            return Promise.reject(new Error("Database update failed"));
        }

        if (arrayHasUndefined(pathArray)){
            console.error(`Passed in array has undefined value`);
            console.log('Passed in Array: ', pathArray);
            return Promise.reject(new Error("Database Re-Number failed"));
        }

        let counter = 0;
        let newJSON = {};
        let currentDatabase = this.getNestedData(pathArray);

        for (let arrayLayer in currentDatabase){
            set(newJSON, [counter], currentDatabase[arrayLayer]);
            counter++;
        }

        console.log('CRAFTED CORRECTED LAYER JSON: ', newJSON);

        //now remove this layer and re-add it
        this.remove(pathArray).then(() => {

            console.log('Adding New Corrected Layer JSON: ', newJSON);

            this.update(pathArray, newJSON).then(() => {

                this.cloudRead(['USERS', this.getNestedData(['UID']), 'UserClass', grade, subject, 'Files'], snapshotVal=>{

                    store.update(['UserClass', grade, subject, 'Files'], snapshotVal)

                    let workingJSON = this.getNestedData(['UserClass', grade, subject, 'Files'])

                    console.log('Need to FIX all inner paths and UI paths now..', workingJSON);
    
                    //need to recursively fix all inner data paths and uiPaths inside this layer of JSON now.
    
                    this.workingFilesJSON = workingJSON;    //Global Variables to be used in recursion
                    this.recursiveFixFilePaths(workingJSON, recursiveDeleteBool, '', '');
    
                    console.log('Recursive Function finished. Fixed Working File JSON: ', this.workingFilesJSON);
    
                    //now need to update database with this new fixed File Tree
                    this.remove(['UserClass', grade, subject, 'Files']).then(() => {
                        this.update(['UserClass', grade, subject, 'Files'], this.workingFilesJSON).then(() => {
    
                            this.workingFilesJSON = undefined;
    
                            if (callback){
                                callback();
                            }
    
                        })
                    })
                })


            })
        })
    }


    /**
     * @desc This will recursively loop through file tree in the database and fix it if needed (The data.path and data.uiPath references need to be fixed)
     * @param {Object} fileJSON - The fileJSON to be fixed (needs to be from Root)
     * @param {String} parentPathPrefix - This needs to be called with the empty string value '' With subsequent recursion cycles this will be set to previous calculated path
     * @param {String} uiParentPathPrefix - This needs to be called with the empty string value '' With subsequent recursion cycles this will be set to previous calculated path
     * @return null
    **/
    recursiveFixFilePaths(fileJSON, recursiveDeleteBool, parentPathPrefix, uiParentPathPrefix) {
        //console.log('One Instance of Recursion with fileJSON: ', fileJSON);
        //this function will accept a fileJSON tree and recursively fix all possible nodes inside and their paths
        // console.log('FileJSON Data Path before boolean check: ', fileJSON.data.path);
        // console.log('FileJSON children before boolean check and array convert: ', fileJSON.children);

        //need to convert fileJSON.children into an array
        if (fileJSON.children){
            let finalArr = [];

            for (let k in fileJSON.children){
                finalArr.push(fileJSON.children[k]);
            }

            fileJSON.children = finalArr;
        }

        //console.log('FileJSON children before boolean check and after array convert: ', fileJSON.children);

        if (fileJSON.data.path != null && fileJSON.children != null) {
            //here you have access to id and dataelements

            if (fileJSON.children.length > 0) {

                for (let t = 0; t < fileJSON.children.length; t++) {
                    //here you have access to each child as json.children[t]
                    //you could do the logic for the current child

                    // console.log('Value of T: ', t);
                    // console.log('Current File Path: ', fileJSON.children[t].data.path);
                    let pathEnd = `children/${t}/`;

                    // console.log('Parent Path Prefix: ', parentPathPrefix);
                    // console.log('Path End Fix: ', pathEnd);
                    let correctedFilePath = `${parentPathPrefix}${pathEnd}`;
                    // console.log('Corrected File Path: ', correctedFilePath);
                    // console.log(' ');

                    //console.log('Current File uiPath: ', fileJSON.children[t].data.uiPath);
                    let correctedFileUIPath = '';
                    if (uiParentPathPrefix === '') {
                        correctedFileUIPath = `/Root/${fileJSON.children[t].title}/`;
                        //console.log('Corrected File uiPath: ', correctedFileUIPath);
                    }
                    else {
                        correctedFileUIPath = `${uiParentPathPrefix}${fileJSON.children[t].title}/`;
                        //console.log('Corrected File uiPath: ', correctedFileUIPath);
                    }

                    //if corrected file paths does not match with current paths then need to update the global scopw working JSON
                    if (fileJSON.children[t].data.path !== correctedFilePath) {
                        //correct the data.path attribute inside this fileJSON node
                        //console.log('Mismatched Data Path, need to fix this in working fileJSON');
                        let workingFileJSON = this.workingFilesJSON;

                        // console.log('Pre-fixed Working File JSON: ', workingFileJSON);

                        let fixPathArr = correctedFilePath.split('/');
                        fixPathArr.pop();
                        fixPathArr.push('data');
                        fixPathArr.push('path');

                        //console.log('Fixed Path Arr: ', fixPathArr);
                        set(workingFileJSON, fixPathArr, correctedFilePath);
                        this.workingFilesJSON = workingFileJSON;

                        //console.log('Fixed Working File JSON: ', this.workingFilesJSON);
                    }

                    if (correctedFileUIPath !== fileJSON.children[t].data.uiPath) {
                        //correct the data.uiPath attribute inside this fileJSON node
                        //console.log('Mismatched Data uiPath, need to fix this in working fileJSON');
                        let workingFileJSON = this.workingFilesJSON;

                        let fixPathArr = correctedFilePath.split('/');
                        fixPathArr.pop();
                        fixPathArr.push('data');
                        fixPathArr.push('uiPath');

                        //console.log('Fixed Path Arr: ', fixPathArr);
                        set(workingFileJSON, fixPathArr, correctedFileUIPath);
                        Database.workingFilesJSON = workingFileJSON;

                        //console.log('Fixed Working File JSON: ', this.workingFilesJSON);


                    }
                    //console.log('------------------------------');

                    //then pass the current child to the next recursive function
                    this.recursiveFixFilePaths(fileJSON.children[t], recursiveDeleteBool, fileJSON.children[t].data.path, fileJSON.children[t].data.uiPath);
                }
            }
        }

        return true;
    }


    /**
     * @desc will convert Local Store JSON into Byte size and return it formatted
     * @return 'File Size as string'
    **/
    getLocalStoreSize(){
        return memorySizeOf(this.getState());
    }


    redirectBasedOnProffession(proffession){
        let currentURL = window.location.href;
        //currentURL = currentURL + '/Student?type=TabMemory&Tab=videoButton';     //this is for localhsot development purpose. In live mode the url will naturally be ended by /Teacher or /Student
        let currentPageArr = currentURL.split('/');
        let currentPage = 'Teacher'
        
        if (currentPageArr[4]) {
            currentPage = currentPageArr[4];
            //now need to split by ? to see if there are any args in it
            let newPageArr = currentPage.split('?');
            currentPage = newPageArr[0];
        }

        if (currentPage === 'Teacher' && proffession === 'Teacher') {
            //console.log(`User is ${proffession} with current page being ${currentPage}. No need to redirect..`);
            //do nothing
        }
        else if (currentPage === 'Student' && proffession === 'Student') {
            //console.log(`User is ${proffession} with current page being ${currentPage}. No need to redirect..`);
            //do nothing
        }
        else if (currentPage === 'Admin' && proffession === 'Admin') {
            //console.log(`User is ${proffession} with current page being ${currentPage}. No need to redirect..`);
            //do nothing
        } 
        else if (currentPage === 'Teacher' && proffession === 'Student') {
            //console.log(`User is ${proffession} with current page being ${currentPage}. Need to redirect to Student Page..`);
            //window.location.href = 'https://edutechs.org/Student/'
            auth.signOut().then(function () {

            }, function (error) {
                console.log(error);

            });
        }
        else if (currentPage === 'Teacher' && proffession === 'Admin') {
            //console.log(`User is ${proffession} with current page being ${currentPage}. Need to redirect to Student Page..`);
            //window.location.href = 'https://edutechs.org/Student/'
            auth.signOut().then(function () {

            }, function (error) {
                console.log(error);

            });
        } 
         else if (currentPage === 'Student' && proffession === 'Teacher') {
            //console.log(`User is ${proffession} with current page being ${currentPage}. Need to redirect to Teacher Page..`);
            //window.location.href = 'https://edutechs.org/Teacher/'
            auth.signOut().then(function () {

            }, function (error) {
                console.log(error);

            });
        }
        else if (currentPage === 'Student' && proffession === 'Admin') {
            //console.log(`User is ${proffession} with current page being ${currentPage}. Need to redirect to Teacher Page..`);
            //window.location.href = 'https://edutechs.org/Teacher/'
            auth.signOut().then(function () {

            }, function (error) {
                console.log(error);

            });
        }
        else if (currentPage === 'Admin' && proffession === 'Teacher') {
            //console.log(`User is ${proffession} with current page being ${currentPage}. Need to redirect to Teacher Page..`);
            //window.location.href = 'https://edutechs.org/Teacher/'
            auth.signOut().then(function () {

            }, function (error) {
                console.log(error);

            });
        }
        else if (currentPage === 'Admin' && proffession === 'Student') {
            //console.log(`User is ${proffession} with current page being ${currentPage}. Need to redirect to Teacher Page..`);
            //window.location.href = 'https://edutechs.org/Teacher/'
            auth.signOut().then(function () {

            }, function (error) {
                console.log(error);

            });
        }
    }




    updateSMSQuota(usedSMS){
        let dbQuota = this.getNestedData(['SMS-Quota', 'v']);
        if (dbQuota){
            let newQuota = parseInt(dbQuota) - parseInt(usedSMS);
            this.update(['SMS-Quota'], {v:newQuota});
        }
    }


    returnAllBatches(){
        let output = {}
        let classesJSON = this.getNestedData(['UserClass']);

        for (let grade in classesJSON){
            for (let subject in classesJSON[grade]){
                for (let batch in classesJSON[grade][subject]['Streams']){
                    output[batch] ={
                        grade: grade,
                        subject: subject,
                        batch: batch,
                        uiBatchName: this.getNestedData(['UserClass', grade, subject, 'Streams', batch, 'BatchName'])
                    } 
                }
            }
        }
        return output
    }
}

export default new Database();

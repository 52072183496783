import { memo, useCallback, useEffect, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider, message } from 'antd';
import { IntlProvider } from "react-intl";

import AppLocale from "../../lngProvider";
import { setInitUrl } from "../../appRedux/actions/Auth";

import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "../../appRedux/actions/Setting";

import {
    LAYOUT_TYPE_BOXED,
    LAYOUT_TYPE_FRAMED,
    LAYOUT_TYPE_FULL,
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL, THEME_TYPE_DARK
} from "../../constants/ThemeSetting";

import "../../i18n"
import {get} from "lodash-es";

import { updateInLocalDatabase,  setActiveTeacherUID } from "../../appRedux/actions";
import { auth } from "../../firebase/firebase";

import firebase from 'firebase';
import Mixpanel from "../../api/Mixpanel";
import { returnMappedProfessionRoute_Route } from "../../util/misc";

const MainApp = lazy(() => import("./MainApp"));
const Welcome = lazy(() => import("../Welcome"));
const SignIn = lazy(() => import("../SignIn")); 
const SignUp = lazy(() => import("../SignUp"));
const DevLogin = lazy(() => import("../SinglePublicPages/DevLogin"));
const PhoneLogin = lazy(() => import("../PhoneLogin"));
const ForgotPassword = lazy(() => import("../ForgotPassword"));
const ForceLogout = lazy(() => import("../ForceLogout"));
const PaymentFail = lazy(() => import("../SinglePublicPages/PaymentFail"));
const PaymentSuccess = lazy(() => import("../SinglePublicPages/PaymentSuccess"));

const Support = lazy(() => import("../SinglePublicPages/Support"));

const URLShortenerRedirect = lazy(() => import("../SinglePublicPages/URLShortenerRedirect"));

const AboutUs = lazy(() => import("../SinglePublicPages/AboutUs"));

const AllNews = lazy(() => import("../AllNews"));
const OpenNews = lazy(() => import("../OpenNews"));
const Privacy = lazy(() => import("../SinglePublicPages/Privacy"));
const Terms = lazy(() => import("../SinglePublicPages/Terms"));

const PaymentFailForStudent = lazy(() => import("../SinglePublicPages/PaymentFailForStudent"));
const PalestineCampaign = lazy(() => import("../SinglePublicPages/PalestineCampaign"));


const database          = firebase.database();
const performance       = firebase.performance();


const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: location }
                    }}
                />}
    />;

const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
        document.body.classList.remove('boxed-layout');
        document.body.classList.remove('framed-layout');
        document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
        document.body.classList.remove('full-layout');
        document.body.classList.remove('framed-layout');
        document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
        document.body.classList.remove('boxed-layout');
        document.body.classList.remove('full-layout');
        document.body.classList.add('framed-layout');
    }
};

const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
        navStyle === NAV_STYLE_DARK_HORIZONTAL ||
        navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
        navStyle === NAV_STYLE_ABOVE_HEADER ||
        navStyle === NAV_STYLE_BELOW_HEADER) {
        document.body.classList.add('full-scroll');
        document.body.classList.add('horizontal-layout');
    } else {
        document.body.classList.remove('full-scroll');
        document.body.classList.remove('horizontal-layout');
    }
};

let styleSheetLink = document.createElement('link');
styleSheetLink.type = 'text/css';
styleSheetLink.rel = 'stylesheet';
document.body.appendChild(styleSheetLink);

window.firstLoad = true;

const App = () => {

    const locale                        = useSelector(({ settings }) => settings.locale);
    const navStyle                      = useSelector(({ settings }) => settings.navStyle);
    const layoutType                    = useSelector(({ settings }) => settings.layoutType);
    const themeColor                    = useSelector(({ settings }) => settings.themeColor);
    const themeType                     = useSelector(({ settings }) => settings.themeType);
    const isDirectionRTL                = useSelector(({ settings }) => settings.isDirectionRTL);

    const dispatch                      = useDispatch();
    const { authUser, initURL, db }     = useSelector(({ auth }) => auth);

    const location                      = useLocation();
    const history                       = useHistory();
    const match                         = useRouteMatch();


    //will only read own Database -- add listeners to it and sync it with local redux
    const initialRead = useCallback(async (uid)=>{

        let uidUSED                 =   uid; //'CAN ENTER OTHER UID TO USE HERE'
        let isFirst                 =   true;

        console.log('INITIAL READ WAS CALLED!', uid);

        // If someone open payment receipt than we skip to load full database
        if(window.location.href.includes('student-payment-receipt?key=')){
           return false;
        }

        // If someone open access control scanner than we skip to load full database
        if(window.location.href.includes('access-control-qr-scanner-nimic?key=')){
            return false;
        }

        database.ref(`USERS/${uidUSED}`).off();
        return database.ref(`USERS/${uidUSED}`).on("value", async snapshot=>{

            window.firstLoad        =   true;
            let userData            =   snapshot.val();

            console.log('Initial Read and User-Data: ', userData);

            if (!snapshot.exists()){
                console.log('User Data for this UID was undefined!');
                message.error('Could not find proper database for you. Please sign-up with different account and login');
                auth.signOut();
                return {};
            } 

            if (isFirst === true){
                console.log('Initial login recorded to Mixpanel.. ', uidUSED);
                Mixpanel.init();
                Mixpanel.setUserData({uid:uidUSED, name: get(userData, ['PublicInfo', 'UserName'], 'Unknown-User') ,  email: get(userData, ['PublicInfo', 'UserEmail'], 'Unknown-Email'), proff: get(userData, ['PublicInfo', 'UserProffession'], 'Unknown-Proff')});
                isFirst = false;
            }

            if (get(snapshot.val(), ['PublicInfo', 'UserProffession'], undefined) === undefined){
                console.log('User Data for this UID was undefined!');
                message.error('Could not find proper database for you. Please sign-up with different account and login');
                auth.signOut();
                return {};
            }

            //check to see which account proff has logged in
            //set-up as Teacher Account
            if (snapshot.val()['PublicInfo']['UserProffession'] === 'Business'){
                //need to manipulate this JSON to have a record of all students in different batches in one unique list
                dispatch(updateInLocalDatabase({ objToAdd: userData}));
                let teacherUid = get(userData, ['PublicInfo', 'UID'], '')
                dispatch(setActiveTeacherUID(teacherUid));
            }
            
        });

    }, [dispatch]);



    useEffect(() => {
        if (isDirectionRTL) {
            document.documentElement.classList.add('rtl');
            document.documentElement.setAttribute('data-direction', 'rtl')
        } else {
            document.documentElement.classList.remove('rtl');
            document.documentElement.setAttribute('data-direction', 'ltr')
        }

        if (themeColor) {
            styleSheetLink.href = `/css/${themeColor}.min.css`;
        }
    }, [themeColor, isDirectionRTL]);

    useEffect(() => {
        if (themeType === THEME_TYPE_DARK) {
            document.body.classList.add('dark-theme');
            styleSheetLink.href = "/css/dark_theme.css";
        } else if (document.body.classList.contains('dark-theme')) {
            document.body.classList.remove('dark-theme');
            styleSheetLink.href = "/css/style.css";
        }
    }, [themeType]);


    useEffect(() => {
        if (initURL === '') {
            dispatch(setInitUrl(location.pathname));
        }
        const params = new URLSearchParams(location.search);

        if (params.has("theme")) {
            dispatch(setThemeType(params.get('theme')));
        }
        if (params.has("nav-style")) {
            dispatch(onNavStyleChange(params.get('nav-style')));
        }
        if (params.has("layout-type")) {
            dispatch(onLayoutTypeChange(params.get('layout-type')));
        }
    }, [location.search, dispatch, initURL, location.pathname]);

    useEffect(() => {
        setLayoutType(layoutType);
        setNavStyle(navStyle);
    }, [layoutType, navStyle]);

    //based on the initial route of the URL the app will behave differently
    useEffect(() => {
        
        // If someone open payment receipt than we skip to load full database
        if(window.location.href.includes('student-payment-receipt?key=')){
            return false;
        }
        // If someone open access control scanner than we skip to load full database
        if(window.location.href.includes('access-conaccess-control-qr-scanner-nimic?key=')){
            return false;
        }

        if(window.location.href.includes('record-educast?key=')){
            return false;
        }

        if(window.location.href.includes('control-educast?key=')){
            return false;
        }
 
        //this means someone tried to visit root domain
        if (location.pathname === '/'){
            console.log('Root Domain Visit Detected. Redirecting to welcome page..');
            history.push('/welcome');
        }

        //this means that an existing account already loaded with db tried to login with different account so need to re-initialize
        else if (authUser && db && initURL !== '' && initURL !== '/signup'){
            //console.log('INIT-APP-USEEFFECT LINE 657: ', {authUser, db, initURL});
            if (db['UID'] !== authUser){
                initialRead(authUser)
                    .then(()=>{
                        database.ref(`USERS/${authUser}/PublicInfo/UserProffession`).once('value')
                        .then(snapshot=>{
                            let thisProff = snapshot.val();
                            if (thisProff){
                                history.push(`/business/dashboard`);
                                console.log('APP INDEX JS Exiting Redirect to: ', `/${returnMappedProfessionRoute_Route(thisProff)}/dashboard`);
                            }
                        })
                    })
            }
        }

        //this means user entered from fresh account with sign-up
        else if (authUser && !db  && initURL === '/signup'){
            //console.log('INIT-APP-USEEFFECT LINE 675: ', {authUser, db, initURL});
            initialRead(authUser)
            .then(()=>{
                database.ref(`USERS/${authUser}/PublicInfo/UserProffession`).once('value')
                .then(snapshot=>{
                    let thisProff = snapshot.val();
                    if (thisProff){
                        history.push(`/business/dashboard`);
                    }
                })
            })
        }

        //this means that completely fresh user login with no previous login attempts
        //or same account login again
        else if (authUser && !db && initURL !== '' && initURL !== '/signup') {
            //console.log('INIT-APP-USEEFFECT LINE 692: ', {authUser, db, initURL});
            initialRead(authUser)
                .then(() => {
                    if (db){
                        history.push(initURL);
                        console.log('APP INDEX JS REDIRECT TO: ', initURL);
                    }
                })
        }


        // fixed overflow issues
        let gxHomePage = document.getElementById('gx-donate-home-page');
        if (gxHomePage) {
            document.body.style.overflowY = 'scroll';
        }
        else {
            document.body.style.overflow = 'hidden';
        }

    }, [authUser, initURL, location, history, db, dispatch, initialRead]);

    const currentAppLocale = AppLocale[locale.locale];

    return (
        <ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? 'rtl' : 'ltr'}>
            <IntlProvider

                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}>

                <Switch>
                    <Route exact path='/welcome' component={Welcome} />
                    <Route exact path='/donate' component={PalestineCampaign} />
                    <Route exact path='/aboutus' component={AboutUs} />
                    <Route exact path='/allnews' component={AllNews} />
                    <Route exact path='/opennews' component={OpenNews} />
                    <Route exact path='/Privacy' component={Privacy} />
                    <Route exact path='/Terms' component={Terms} />
                    <Route exact path='/Support' component={Support} />
                    <Route exact path='/devlogin' component={DevLogin} />
                    <Route exact path='/paymentfail' component={PaymentFail} />
                    <Route exact path='/student-payment-fail' component={PaymentFailForStudent} />
                    <Route exact path='/paymentsuccess' component={PaymentSuccess} />

                    <Route exact path='/signin' component={SignIn} />
                    <Route exact path='/login' component={SignIn} />
                    <Route exact path='/signup' component={SignUp} />
                    <Route exact path='/phone-login' component={PhoneLogin} />
                    <Route exact path='/forgot-password' component={ForgotPassword} />
                    <Route exact path='/force-logout' component={ForceLogout} />

                    <Route exact path='/r' component={URLShortenerRedirect} />

                    <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location}
                        component={MainApp} />
                </Switch>
            </IntlProvider>
        </ConfigProvider>
    )
};

export default memo(App);